.hiw__card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.2rem;
  letter-spacing: 0.035em;
  color: #0e0d0d;
}

.hiw__card-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: 0.035em;
  color: #5e5e5e;
}
