.footer {
  padding: 0;
  color: white;
}

.footer__main,
.footer__bottom {
  width: 100%;
}

.footer__main {
  padding: 4rem;
  background: #ff385c;
}

.footer__bottom {
  padding: 2.5rem;
  background: #b71232;
}

.footer__heading {
  font-style: normal;
  font-weight: 700;
  font-size: 1.92rem;
  line-height: 3.8rem;
}

.footer__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer__item:not(:last-child) {
  margin-bottom: 2rem;
}

.footer__link,
.footer__link:hover,
.footer__text {
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  text-decoration: none;
  text-transform: capitalize;
}
