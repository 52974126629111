#contact {
  background-color: #f5f5f5;
}

.contact__form-wrapper {
  padding: 39px 32px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact__form-group {
  width: 100%;
}

.contact__form-group:not(:last-child) {
  margin-bottom: 27px;
}

.contact__form-label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 16px;
  color: #363535;
  opacity: 0.8;
}

.contact__form-icon {
  background-color: transparent;
  border: none;
}

.contact__form-input__wrapper {
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  padding-right: 2px;
}

.contact__form-input__wrapper:hover,
.contact__form-input__wrapper:hover .contact__form-input:focus {
  border-color: #ff385c;
}

.contact__form-input {
  border: none;
}

.contact__form-input:focus {
  outline: none;
  box-shadow: none;
}
