.download__wrapper {
  margin-top: 120px;
}

.subscribe {
  padding: 48px;
  background: #ff385c;
  border-radius: 32px;
}

.subscribe .primary__heading,
.subscribe .primary__heading .sub__text {
  color: white;
}
