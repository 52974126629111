.input__wrapper {
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
}

.input__field {
  padding: 1.6rem 5.2rem;
  border: 0.1rem solid #d5d5d5;
  border-radius: 0.8rem;
  background-color: transparent;
  width: 100%;
  transition: all 0.4s ease-in-out;
}

.input__textarea {
  width: 100%;
  padding: 1.6rem 2.6rem;
  background: #f5f5f5;
  border-radius: 0.8rem;
  border: unset;
}

.input__field,
.input__textarea,
.subscribe__input {
  font-size: 1.5rem;
  color: #323233;
}

.input__field:focus,
.input__textarea:focus {
  outline: none;
  box-shadow: none;
  border-color: #ff385c;
  color: #afaeae;
}

.input__icon {
  position: absolute;
  top: 50%;
  left: 1.6rem;
  transform: translateY(-50%);
  z-index: 1;
}

.input__icon svg {
  stroke: #555555;
  transition: all 0.4s ease-in-out;
}

.input__field:focus + .input__icon svg {
  stroke: #ff385c;
}

.subscribe__input,
.subscribe__input:focus,
.subscribe__input:focus-visible {
  width: 100%;
  padding: 2.4rem;
  padding-right: 15rem;
  background: #ffffff;
  border-radius: 2.4rem;
  border: unset;
}

.subscribe__btn {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
