/* #about {
  margin-top: 2rem;
} */

.about__card {
  padding: 3.2rem 2.4rem;
  border-radius: 1.6rem;
  background-color: #fff;
  box-shadow: 18px 19px 24px -12px rgba(151, 156, 167, 0.3);
  -webkit-box-shadow: 18px 19px 24px -12px rgba(151, 156, 167, 0.3);
  -moz-box-shadow: 18px 19px 24px -12px rgba(151, 156, 167, 0.3);
}

.about__card:not(:last-child) {
  margin-bottom: 2rem;
}

.about__card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2rem;
  letter-spacing: 0.1rem;
  color: #ff385c;
  margin-bottom: 1.6rem;
}

.about__card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.035em;
  color: #363535;
}
