.primary-button,
.contact-button,
.subscribe-button,
.primary-button:hover,
.contact-button:hover,
.subscribe-button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  background: #ff385c;
  color: white;
  text-decoration: none;
}
.primary-button {
  padding: 1.4rem 4rem;
  border-radius: 0.8rem;
}

.subscribe-button {
  padding: 1.6rem 2.4rem;
  border-radius: 1.6rem;
}

.contact-button {
  padding: 1.4rem 3.2rem;
  border-radius: 0.8rem;
  font-style: normal;
  color: white;
}

@media screen and (min-width: 600px) {
  .primary-button {
    width: fit-content;
  }
}

@media screen and (max-width: 600px) {
  .primary-button {
    width: 100%;
  }
}
