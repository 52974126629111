/* #features {
  margin-top: 0px;
} */

/* #features .features__img {
  width: 80% !important;
} */

.features__text {
  font-style: normal;
  font-weight: 700;
  font-size: 4.4rem;
  line-height: 5.4rem;
  letter-spacing: 0.1rem;
  color: #37474f;
}
