@font-face {
  font-family: "Helvetica";
  src: url(assets/fonts/Helvetica.ttf);
}

html {
  margin: 0;
  font-family: "Helvetica", sans-serif;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 75em) {
  html {
    font-size: 50%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (max-width: 37.5em) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}
