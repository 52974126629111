.banner {
  margin-bottom: 4rem;
}

.banner__title {
  font-style: normal;
  font-weight: 700;
  font-size: 5.4rem;
  line-height: 8.8rem;
  letter-spacing: 0.1rem;
  color: #000000;
}

.banner__title span {
  color: #ff385c;
}

.banner__text {
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 0.1rem;
  color: #000000;
}

.banner__img-wrapper,
.banner__link-wrapper {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .banner__img-wrapper {
    margin-bottom: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .banner__title {
    font-size: 4rem;
    line-height: 5rem;
  }

  .banner__text {
    font-size: 1.7rem;
    line-height: 2rem;
  }
}
