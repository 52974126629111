.accordion-button {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.035em;
  color: #0e0d0d;
  padding: 3rem 1rem;
}
.accordion-button:not(.collapsed) {
  color: #0e0d0d;
  background-color: #fff;
}

/* .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
} */

.accordion-body {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0.09rem;
  color: #333333;
}
