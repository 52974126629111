.nav-link {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 3rem;
  letter-spacing: 0.1rem;
  color: #aaaaaa;
  transition: all 0.4s ease-in-out;
}

.nav-link:not(:last-child) {
  margin-right: 3rem;
}

.nav-link:hover,
.nav-link.active {
  color: #ff385c !important;
}

.navbar-light .navbar-toggler {
  border-color: transparent;
}

@media screen and (max-width: 600px) {
  .nav-link:not(:last-child) {
    margin-right: 0rem;
  }
}
