.primary__heading {
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  letter-spacing: 0.1rem;
  color: #000000;
  /* margin-bottom: 6rem; */
}

.primary__heading span {
  color: #ff385c;
}

.primary__heading .sub__text {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: 0rem;
  color: #000000;
  margin: 0;
  margin-top: 1.2rem;
  margin-bottom: 3rem;
}

.para {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.035em;
  color: #363535;
}

.para:not(:last-child) {
  margin-bottom: 2rem;
}

.small__text {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  color: #808080;
}

section {
  padding: 8rem;
}

.no__underline {
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .primary__heading {
    font-size: 4rem;
  }
  .primary__heading .sub__text {
    font-size: 1.7rem;
    margin-top: 0.4rem;
  }
  .para {
    font-size: 1.6rem;
  }
}

/* * {
  border: 1px solid black;
} */
