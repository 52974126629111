body {
  color: #343434;
  font-size: 1.6rem;
}

.terms_h {
  font-size: 2.8rem;
  font-weight: 900;
}

.terms_sh {
  font-size: 2rem;
  font-weight: 700;
}

.terms_h, .terms_sh {
  color: #010101;
}

ul, ol {
  list-style-position: outside;
}

ul li:not(:last-child), ol li:not(:last-child) {
  margin-bottom: 1.4rem;
}