.social__list {
  list-style: none;
  padding: 0;
  display: flex;
  margin-bottom: 0;
  width: fit-content;
}

.social__item:not(:last-child) {
  margin-right: 1.5rem;
}

.social__link {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social__link img {
  width: 1.8rem;
}
